import { IAM } from '@/clients/iam';
import { QueryClient } from '@tanstack/react-query';
import { createBrowserHistory, createRouter } from '@tanstack/react-router';
import { SERVER_URL } from './constants';
import { routeTree } from './routeTree.gen';

// Deal with development-mode hijinks (scripts have a different origin than the window)
// TODO: be a bit more paranoid about security i.e. check the document base?
const createHref = document.baseURI.startsWith(window.origin)
  ? undefined
  : (path: string) => `${window.origin}${path}`;
const history = createBrowserHistory({ createHref });
export const router = createRouter({ history, routeTree });

export const sdk = new IAM({ serverURL: SERVER_URL.toString() });

export const queryClient = new QueryClient();

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
