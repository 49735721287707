import type { SDKOptions } from '@crossnokaye/typescript-sdk';
import { AtlasCore } from '@crossnokaye/typescript-sdk/core.js';

/**
 * An OAuth 2.0 bearer grant with some additional CrossnoKaye-specific fields.
 */
interface ScopedGrant {
  accessToken: string;
  expiresIn: number;
  scope: string;
  tokenType: 'Bearer';
}

/**
 * Hand-coded client for the IAM system; performs OAuth2 grants, plus SSO provider discovery (a unique CrossnoKaye thing).
 */
export class IAM extends AtlasCore {
  constructor(options: SDKOptions = {}) {
    super(options);
  }

  /**
   * Discover the OAuth authorization flow URL for the given email (if it is handled via an SSO provider).
   * If the email is handled via password-based login, the response will be an empty object.
   */
  discover(email: string, state?: string): Promise<{ url?: string }> {
    const url = new URL('/api/login/v2/loginurl', this._baseURL ?? undefined);
    url.searchParams.append('email', email);
    if (state) {
      url.searchParams.append('state', state);
    }
    return fetch(url).then((res) => (res.status === 200 ? res.json() : Promise.reject(res)));
  }

  /**
   * Perform an OAuth 2.0 authorization-code grant.
   */
  grantWithCode(code: string, redirectURI: string): Promise<ScopedGrant> {
    const url = new URL('/api/login/v2/login', this._baseURL ?? undefined);
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ grant_type: 'authorization_code', code, redirect_uri: redirectURI }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) =>
      (res.status === 200 ? res.json() : Promise.reject(res)).then(
        ({ access_token, expires_in, token_type, scope }) => ({
          accessToken: access_token,
          expiresIn: expires_in,
          tokenType: token_type,
          scope,
        })
      )
    );
  }

  /**
   * Perform an OAuth 2.0 password grant.
   */
  grantWithPassword(email: string, password: string): Promise<ScopedGrant> {
    const url = new URL('/api/login/v2/login', this._baseURL ?? undefined);
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ grant_type: 'password', email, password }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) =>
      (res.status === 200 ? res.json() : Promise.reject(res)).then(
        ({ access_token, expires_in, token_type, scope }) => ({
          accessToken: access_token,
          expiresIn: expires_in,
          tokenType: token_type,
          scope,
        })
      )
    );
  }

  /**
   * Perform an OAuth 2.0 refresh-token grant.
   */
  grantWithRefreshToken(refreshToken: string): Promise<{ accessToken: string; expiresIn: number }> {
    const url = new URL('/api/login/v2/login', this._baseURL ?? undefined);
    return fetch(url, {
      method: 'POST',
      body: JSON.stringify({ grant_type: 'refresh_token', refresh_token: refreshToken }),
      headers: { 'Content-Type': 'application/json' },
    }).then((res) =>
      (res.status === 200 ? res.json() : Promise.reject(res)).then(
        ({ access_token, expires_in, token_type, scope }) => ({
          accessToken: access_token,
          expiresIn: expires_in,
          tokenType: token_type,
          scope,
        })
      )
    );
  }

  showUserinfo(accessToken: string): Promise<{ email: string; name: string; sub: string }> {
    const url = new URL('/api/login/v2/userinfo', this._baseURL ?? undefined);
    return fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${accessToken}` } }).then(
      (res) => (res.status === 200 ? res.json() : Promise.reject(res))
    );
  }
}
