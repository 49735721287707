const { hostname } = window.location;
const dotTLD = hostname.lastIndexOf('.');
const dotSLD = dotTLD ? hostname.lastIndexOf('.', dotTLD - 1) : -1;

/**
 * The domain to use for cookies.
 * Typically the second-level domain prefixed with a dot (e.g. .atlaslocal.io).
 */
export const COOKIE_DOMAIN = dotSLD > 0 ? hostname.slice(dotSLD) : hostname;

/**
 * Base URL of the server to use for API requests.
 * Typically the origin's protocol and second-level domain (e.g. https://atlaslocal.io).
 */
export const SERVER_URL = new URL(
  dotSLD > 0 ? window.origin.replace(hostname, hostname.slice(dotSLD + 1)) : window.origin
);

export const StorageKey = {
  ephemeralCookie: 'ephemeral_login',
  flow: 'flow',
  rememberMe: 'rememberMe',
} as const;
